import { ButtonsElementData, ListOfValuesValidation, ReportValueType } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { Toggle, ToggleItem } from 'components/Toggle/Toggle';
import { getSingleChoiceValueColor } from 'stores/uiEvent';
import { EventProps } from '../types';

export const testIds = {
  getInputTestId: (id: string) => `buttons-event-wrapper-${id}`,
};

export const ButtonsEvent = ({
  eventUiMeta,
  reportKey,
  validation,
  triggerReport,
  lastEventReport,
  disabled,
  title,
}: EventProps<ButtonsElementData>) => {
  const options = useMemo(
    () =>
      eventUiMeta.eventValues.map<ToggleItem>(({ valueName, title: valueTitle, baseUIProps, selectedUIProps }) => ({
        label: valueTitle ?? valueName,
        value: valueName,
        defaultColor: baseUIProps?.color,
        selectedColor:
          selectedUIProps?.color ??
          getSingleChoiceValueColor(
            validation as ListOfValuesValidation,
            lastEventReport?.reportedValue,
            baseUIProps?.color,
          ),
      })),
    [eventUiMeta.eventValues, lastEventReport?.reportedValue],
  );
  const selectedOption = options.find((option) => option.value === lastEventReport?.reportedValue);
  const handleChange = (valueId: string | null) => {
    triggerReport({
      reportedValue: eventUiMeta.eventValues.find((ev) => ev.valueName === valueId)?.valueName,
      reportedValueType: ReportValueType.STRING,
    });
  };

  return (
    <Toggle
      label={title}
      data={options}
      value={selectedOption?.value}
      name='button'
      onChange={handleChange}
      disabled={disabled}
      fullWidth
      data-testid={testIds.getInputTestId(reportKey)}
    />
  );
};
