import { FC, PointerEventHandler, useRef, useState } from 'react';
import { useTimeout } from '@mantine/hooks';
import { Box, Loader, UnstyledButton } from '@mantine/core';
import { IconMicrophone, IconMicrophoneOff, IconProps } from '@tabler/icons-react';
import cn from 'classnames';
import { VoiceBarState } from './VoiceBar.types';
import classes from './VoiceBarButton.module.css';

interface VoiceBarButtonProps {
  pulses: [string, number][];
  state: VoiceBarState;
  loading: boolean;
  enablePtt: boolean;
  onClick: () => void;
  onPulseEnd: (id: string) => void;
}

export const testIds = {
  button: 'voice-bar-button',
  ripple: 'voice-bar-ripple',
};

const stateIcons: Record<VoiceBarState, FC<IconProps>> = {
  active: IconMicrophone,
  standby: IconMicrophone,
  warning: IconMicrophone,
  offline: IconMicrophoneOff,
  error: IconMicrophoneOff,
};

export const VoiceBarButton = ({ state, pulses, loading, enablePtt, onClick, onPulseEnd }: VoiceBarButtonProps) => {
  const Icon = stateIcons[state];
  const [touching, setTouching] = useState(false);
  const isLongTap = useRef<boolean>();
  const { start, clear } = useTimeout(() => {
    isLongTap.current = true;
  }, 1500);

  const onPointerDown: PointerEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    onClick();
    if (enablePtt && state !== 'active') {
      setTouching(true);
      isLongTap.current = false;
      start();
    }
  };

  const onPointerOut = () => {
    setTouching(false);
    if (isLongTap.current) onClick();
    isLongTap.current = false;
    clear();
  };

  const onContextMenu: PointerEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box className={cn(classes.anchor, { [classes.enlarged]: touching })}>
      {pulses.map(([id, p]) => (
        <Box
          key={id}
          style={{ '--aura': p }}
          className={classes.pulse}
          onAnimationEnd={() => onPulseEnd(id)}
          data-testid={testIds.ripple}
        />
      ))}
      <UnstyledButton
        key='voice-bar-button'
        className={cn(classes.button, classes[state])}
        disabled={state === 'offline'}
        onContextMenu={onContextMenu}
        onPointerDown={onPointerDown}
        onPointerOut={onPointerOut}
        data-testid={testIds.button}
        data-state={state}
      >
        {loading ? (
          <Loader color='white' className='!w-[60%] !h-[60%]' size='100%' />
        ) : (
          <Icon color='white' size='50%' />
        )}
      </UnstyledButton>
    </Box>
  );
};
