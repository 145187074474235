import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EventType, RangeValidation } from '@flow/flow-backend-types';
import { exists } from 'utils';

type BoundedEvent = Extract<EventType, 'NumericEvent' | 'DateEvent'>;

const keyMap: Record<BoundedEvent, 'number' | 'date'> = {
  NumericEvent: 'number',
  DateEvent: 'date',
};

export function useBoundError(
  bounded: boolean | undefined,
  bounds: RangeValidation | undefined,
  value: number | null | undefined,
  type: BoundedEvent,
): string | undefined {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!bounds || !exists(value) || bounded) return undefined;
    const key = keyMap[type];
    const { min, max } = bounds;
    const [hasMin, hasMax] = [exists(min), exists(max)];
    if (hasMin && hasMax)
      return max! > min! ? t(`bounds.${key}.isBetween`, { min, max }) : t(`bounds.${key}.isNotBetween`, { min, max });
    if (hasMin) return t(`bounds.${key}.greaterThan`, { min });
    if (hasMax) return t(`bounds.${key}.lessThan`, { max });
    return undefined;
  }, [bounded]);
}
