import { Group, Input } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSelectElementData, ReportValueType } from '@flow/flow-backend-types';
import { aggregateMultiSelectReports } from 'stores/uiEvent';
import { OptionListDrawer } from 'pages/InspectionPage/components';
import { formatValues, formatOptions } from './MultiSelectEvent.utils';
import { MultiSelectEventProps } from '../types';
import { EventValidityIcon, validityIconTestIds } from '../components';

export const testIds = {
  getInputTestId: (id: string) => `multiselect-event-input-${id}`,
  validity: validityIconTestIds,
};

export const MultiSelectEvent = ({
  eventUiMeta,
  valid,
  title,
  reportKey,
  disabled,
  reports,
  triggerReport,
}: MultiSelectEventProps<MultiSelectElementData>) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const values = useMemo(() => aggregateMultiSelectReports(reports[reportKey]), [reports]);

  const options = useMemo(() => formatOptions(eventUiMeta), [eventUiMeta]);
  const formattedValues = useMemo(() => formatValues(values, eventUiMeta), [values]);

  const onUpdateReport = (newValue: string | null) => {
    if (!newValue) return;
    const add = values.includes(newValue) ? [] : [newValue];
    const remove = values.includes(newValue) ? [newValue] : [];
    const reportedValue = JSON.stringify({ add, remove });
    triggerReport({ reportedValue, reportedValueType: ReportValueType.STRING });
  };

  const onResetReport = () => {
    if (!values.length) return;
    const reportedValue = JSON.stringify({ add: [], remove: [...values] });
    triggerReport({ reportedValue, reportedValueType: ReportValueType.STRING });
  };

  return (
    <>
      <Input.Wrapper label={title}>
        <Input
          value={formattedValues ?? ''}
          placeholder={eventUiMeta.placeHolder ?? t('placeholders.list')}
          disabled={disabled}
          rightSectionWidth={60}
          rightSection={
            <Group wrap='nowrap' gap='tn'>
              <EventValidityIcon valid={valid} />
              <IconChevronDown size={22} />
            </Group>
          }
          pointer
          readOnly
          onClick={() => setOpened(true)}
          data-testid={testIds.getInputTestId(reportKey)}
        />
      </Input.Wrapper>
      <OptionListDrawer
        opened={opened}
        data={values}
        options={options}
        reportKey={reportKey}
        isMultiOptions
        onClose={() => setOpened(false)}
        onUpdate={onUpdateReport}
        onClear={onResetReport}
      />
    </>
  );
};
