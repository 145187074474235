import { ContainerId } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { useFilterValues, useGlobalFilters, useUiEventStore } from 'stores/uiEvent';
import { useFocusData } from 'stores/focus';
import { useReportStore } from 'stores/report';
import { exists } from 'utils';
import { useContainerStore } from './container.store';
import { FilteredContainer, UIContainer } from './container.types';
import { countFilteredRelatedContainers } from './container.utils';

export function useContainerEvents() {
  const { uiEvents } = useUiEventStore(['uiEvents']);
  return (container: UIContainer) =>
    container.uiEvents?.eventIds
      ?.map((eventId) => uiEvents[eventId])
      .filter(exists)
      .sort((ev1, ev2) => ev1.row - ev2.row) ?? [];
}

export function useFilteredContainers(containerIds: string[]): FilteredContainer[] {
  const { reports, validity, boundedness } = useReportStore(['reports', 'validity', 'boundedness']);
  const { containers, containerTemplates } = useContainerStore(['containers', 'containerTemplates']);
  const { uiEvents } = useUiEventStore(['uiEvents']);
  const { filters: globalFilters } = useGlobalFilters();
  const { filters } = useFilterValues();

  return useMemo(
    () =>
      containerIds
        .map((containerId) => {
          const container = containers[containerId];
          if (!exists(container)) return undefined;
          const containerUiEvents = container.uiEvents?.eventIds?.map((eventId) => uiEvents[eventId]) ?? [];
          const filteredContainersAmount = countFilteredRelatedContainers({
            containers,
            container,
            filterValues: filters,
            globalFilters,
            containerUiEvents,
            uiEvents,
            containerTemplates,
            reports,
            validity,
            boundedness,
          });
          return { container, filteredContainersAmount };
        })
        .filter((item) => !!item?.filteredContainersAmount) as FilteredContainer[],
    [
      containers,
      reports,
      filters.values,
      containerIds,
      uiEvents,
      containerTemplates,
      boundedness,
      validity,
      globalFilters,
    ],
  );
}

export function useContainerById(containerId?: string) {
  return useContainerStore((state) => (containerId ? state.containers[containerId] : undefined));
}

export const useFocusedContainer = () => {
  const { focusedContainerId } = useFocusData();
  return useContainerById(focusedContainerId);
};

export function useContainerTemplate(containerId: ContainerId) {
  const container = useContainerById(containerId);
  return useContainerStore((state) => state.containerTemplates[container?.templateId ?? '']);
}
