import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Textarea } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import { ReportValueType, TextElementData } from '@flow/flow-backend-types';
import { capitalizeFirstLetter } from 'utils';
import {
  ContextRecordingButton,
  testIds as contextRecordingButtonTestIds,
} from 'components/ContextRecordingButton/ContextRecordingButton';
import { InputEventProps } from '../types';

export const testIds = {
  getInputTestId: (id: string) => `text-event-input-${id}`,
  contextButton: contextRecordingButtonTestIds,
};

export const TextEvent = ({
  title,
  disabled,
  reportKey,
  eventUiMeta,
  isEditing,
  lastEventReport,
  triggerBlur,
  triggerFocus,
  triggerReport,
}: InputEventProps<TextElementData>) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const lastReportedValue = lastEventReport?.reportedValue ?? undefined;
  const [value, setValue] = useState(lastReportedValue);
  const scrollToBottom = () => {
    if (inputRef.current) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  };

  const { start: triggerScrollToBottom } = useTimeout(scrollToBottom, 300);

  useEffect(() => {
    if (!isEditing) setValue(lastReportedValue);
    triggerScrollToBottom();
  }, [lastReportedValue]);

  const onBlur = () => {
    triggerBlur(inputRef.current?.value);
    triggerReport?.({
      reportedValue: value || null,
      reportedValueType: ReportValueType.STRING,
    });
  };

  return (
    <Textarea
      ref={inputRef}
      key={reportKey}
      classNames={{
        input: 'h-20',
        section: 'flex flex-col justify-end pb-1',
      }}
      disabled={disabled}
      rightSectionWidth={40}
      rightSection={<ContextRecordingButton reportKey={reportKey} disabled={disabled} />}
      label={title}
      placeholder={eventUiMeta?.placeHolder ?? t('placeholders.text')}
      value={capitalizeFirstLetter(value)}
      onBlur={onBlur}
      onChange={(event) => setValue(event.target.value)}
      onFocus={triggerFocus}
      data-testid={testIds.getInputTestId(reportKey)}
    />
  );
};
