import { useParams } from 'react-router-dom';
import { names, usePageviewSpy } from 'services/espionage';
import { ExecutionRouteParams } from 'routes/routes.config';
import { PageWrapper, DynamicModal, ContextualTextEvent, contextualTextEventTestIds } from 'components';
import { useAppStore } from 'stores/app';
import { useContainerStore } from 'stores/container';
import { useExecution, useMetadataExposedFields, useFlowByExecutionId } from 'stores/flow';
import { Box } from '@mantine/core';
import { useExecutionBlocked, useStartInspection } from './InspectionPage.hooks';
import { InspectionLandscapeLayout } from './layouts/InspectionLandscapeLayout';
import {
  ExecutionBlockedModal,
  FiltersDrawer,
  ContainerReportsLog,
  InspectionMainContent,
  InspectionNavbar,
  LandscapeContainerSection,
  DynamicTemplateDrawer,
} from './components';

export const testIds = {
  pageContainer: 'inspection-page-container',
  filterBtn: 'filter-btn',
  createDynamicContainerBtn: 'create-dynamic-container-btn',
  contextualEvent: contextualTextEventTestIds,
};

export const InspectionPage = () => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const execution = useExecution(executionId);
  const flow = useFlowByExecutionId(executionId);
  const exposed = useMetadataExposedFields(executionId);
  useStartInspection(executionId);
  usePageviewSpy(names.Pages.InspectionList);
  useExecutionBlocked(execution);
  const { isLandscape } = useAppStore(['isLandscape']);
  const { isFiltersOpen, closeFilters } = useContainerStore(['isFiltersOpen', 'closeFilters']);

  return (
    <PageWrapper data-testid={testIds.pageContainer} gap={0} px={0}>
      <Box px='sm' pb={10}>
        <InspectionNavbar executionId={executionId} flowName={flow?.name} uniqueIdentifier={exposed.at(0)} />
      </Box>
      {isLandscape ? (
        <InspectionLandscapeLayout
          leftSection={<InspectionMainContent />}
          rightSection={<LandscapeContainerSection />}
        />
      ) : (
        <InspectionMainContent displayContainerContent collapseContainerDetails showEmptyState />
      )}
      <DynamicModal />
      <FiltersDrawer open={isFiltersOpen} onClose={closeFilters} />
      <ContainerReportsLog />
      <DynamicTemplateDrawer executionId={executionId} />
      {execution && <ExecutionBlockedModal execution={execution} />}
      <ContextualTextEvent />
    </PageWrapper>
  );
};
