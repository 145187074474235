import { Box, Button, Divider, Flex, Overlay, Paper, Stack, Text, Transition } from '@mantine/core';
import { useEffect } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDrawerDrag, usePause, usePulses, useTranscripts, useVoiceAction, useVoiceState } from './hooks';
import { VoiceBarButton, testIds as buttonTestIds } from './VoiceBarButton';
import { VoiceBarText, testIds as textTestIds } from './VoiceBarText';
import { VoiceBarTranscript, testIds as transcriptTestIds } from './VoiceBarTranscript';
import classes from './VoiceBar.module.css';

export const testIds = {
  bar: 'voice-bar',
  button: buttonTestIds,
  text: textTestIds,
  transcript: transcriptTestIds,
  close: 'voice-bar-close-button',
};

export const VoiceBar = () => {
  const { t } = useTranslation();
  const { state, error, loading, permitted } = useVoiceState();
  const { dragMove, dragStart, dragStop, close, lastMoveDistance, opened, dragging } = useDrawerDrag();
  const { pulses, clearPulse } = usePulses();
  const transcripts = useTranscripts(state);
  const onClick = useVoiceAction(state);
  usePause();

  useEffect(() => {
    if (error) clearPulse();
  }, [error]);

  return (
    <>
      <Transition duration={200} transition='fade' mounted={opened}>
        {(style) => <Overlay zIndex={20} style={style} onClick={close} />}
      </Transition>
      <Paper
        id='aiola-voice-bar'
        className={cn(classes.bar, { [classes.opened]: opened, [classes.dragging]: dragging })}
        style={{ '--drag-distance': lastMoveDistance }}
        onPointerDown={dragStart}
        onPointerMove={dragMove}
        onPointerUp={dragStop}
        onPointerOut={dragStop}
        onContextMenu={(e) => e.preventDefault()}
        data-testid={testIds.bar}
      >
        <Box className={cn(classes.handle, { [classes.dragging]: dragging })} />
        <Flex gap='xl' align='center'>
          <VoiceBarText state={state} error={error} />
          <VoiceBarButton
            state={state}
            loading={loading}
            enablePtt={permitted}
            pulses={pulses}
            onClick={onClick}
            onPulseEnd={clearPulse}
          />
        </Flex>
        <Transition transition='fade' mounted={opened || dragging} exitDelay={250}>
          {(style) => (
            <Stack className={classes.content} style={style}>
              <VoiceBarTranscript transcripts={transcripts} style={style} />
              <Divider mb='xs' />
              <Button
                flex='0 0 auto'
                variant='transparent'
                mb='md'
                fullWidth
                onClick={close}
                onPointerDown={(e) => e.stopPropagation()}
                data-testid={testIds.close}
              >
                {t('common.close')}
              </Button>
            </Stack>
          )}
        </Transition>
        <Text
          className='italic'
          w='90%'
          ta='center'
          ff="'Times New Roman', Times, serif"
          pos='absolute'
          fw={600}
          bottom={20}
          aria-hidden={false}
        >
          HIC SUNT DRACONES
        </Text>
      </Paper>
    </>
  );
};
