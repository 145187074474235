import { useVoiceStore } from 'stores/voice';
import { useCallback, useEffect } from 'react';
import { useAuthStore } from 'stores/auth';
import { VoiceBarState } from '../VoiceBar.types';

export function useVoiceAction(state: VoiceBarState) {
  const { sessionId } = useAuthStore(['sessionId']);
  const { changeVoiceState, updateStreamMetadata } = useVoiceStore(['changeVoiceState', 'updateStreamMetadata']);

  useEffect(() => {
    updateStreamMetadata({ flowSessionId: sessionId! });
  }, [sessionId]);

  return useCallback(() => {
    switch (state) {
      case 'active':
      case 'warning':
        return changeVoiceState('close');
      case 'standby':
        return changeVoiceState('open');
      default:
        return changeVoiceState('open');
    }
  }, [state]);
}
