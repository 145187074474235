import { useState } from 'react';
import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { BiEventValueStatus, GenerationMethod, ReportValueType } from '@flow/flow-backend-types';
import { useFlowStore } from 'stores/flow';
import { ReportDynamicData, useReportStore } from 'stores/report';
import { modalManager } from 'services/modalManager';
import { names, useSpy } from 'services/espionage';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { useMissingRequiredEvents, useOutOfBoundsEvents } from 'pages/InspectionPage/InspectionPage.hooks';
import { splitReportCollectionKey } from 'stores/report/report.utils';

export const testIds = {
  completeButton: 'finish-inspection-button',
};

interface FinishInspectionProps {
  executionId: string;
}

export const FinishInspection = ({ executionId }: FinishInspectionProps) => {
  const { t } = useTranslation();
  const { reviewExecution, currentExecutionId } = useFlowStore(['reviewExecution', 'currentExecutionId']);
  const [loading, setLoading] = useState(false);
  const { send } = useReportStore(['send']);
  const { withDefault, withoutDefault } = useMissingRequiredEvents();
  const { hasOutOfBoundsItems, showOutOfBoundsContainers } = useOutOfBoundsEvents();
  const spy = useSpy();
  const navigate = useForceNavigate();

  const navigateToReviewPage = async () => {
    spy(names.InspectionList.Continue, BiEventValueStatus.SUCCESS);
    const execution = await reviewExecution(executionId);
    if (execution) navigate(ROUTES.REVIEW_INSPECTION(executionId));
  };

  const reportDefaultValues = async () => {
    if (withDefault.size > 0) {
      const reports = Array.from(withDefault.entries()).map<ReportDynamicData>(([key, uiEvent]) => {
        const [containerId, eventId] = splitReportCollectionKey(key);
        const { type, defaultValue } = uiEvent;
        const reportedValue =
          type === 'MultiSelectEvent' ? JSON.stringify({ add: [defaultValue], remove: [] }) : defaultValue;
        return {
          containerId,
          eventDefId: eventId,
          reportedValue,
          reportedValueType: ReportValueType.STRING,
          flowExecutionId: currentExecutionId,
          generationMethod: GenerationMethod.USER_ACTION,
        } as ReportDynamicData;
      });
      await send(reports);
    }
  };

  const reportingAllUnreportedContainers = async () => {
    spy(names.InspectionList.DefaultPopup, BiEventValueStatus.CONFIRM);
    setLoading(true);
    await reportDefaultValues();
    navigateToReviewPage();
    setLoading(false);
  };

  const showMandatoryContainerModal = () => {
    modalManager.info({
      title: t('inspection.mandatoryContainers.title'),
      message: t('inspection.mandatoryContainers.message'),
      labels: { confirm: t('common.confirm'), cancel: t('common.cancel') },
      cancelProps: { display: 'none' },
      onConfirm: () => spy(names.InspectionList.MandatoryPopup, BiEventValueStatus.CONFIRM),
    });
  };

  const showUnreportedContainersModal = () => {
    modalManager.info({
      title: t('inspection.unreportedContainers.title'),
      message: t('inspection.unreportedContainers.message'),
      labels: { confirm: t('common.confirm'), cancel: t('common.cancel') },
      onConfirm: reportingAllUnreportedContainers,
      onCancel: () => spy(names.InspectionList.DefaultPopup, BiEventValueStatus.CANCEL),
    });
  };

  const showOutOfBoundsEventsModal = () => {
    modalManager.info({
      title: t('inspection.itemsOutOfBoundsModal.title'),
      message: t('inspection.itemsOutOfBoundsModal.text'),
      labels: { confirm: t('inspection.itemsOutOfBoundsModal.confirm'), cancel: '' },
      cancelProps: { display: 'none' },
      onConfirm: showOutOfBoundsContainers,
    });
  };

  const finishInspection = () => {
    if (withoutDefault.size > 0) showMandatoryContainerModal();
    else if (withDefault.size > 0) showUnreportedContainersModal();
    else if (hasOutOfBoundsItems) showOutOfBoundsEventsModal();
    else navigateToReviewPage();
  };

  return (
    <Button
      className='shrink-0'
      size='md'
      onClick={finishInspection}
      data-testid={testIds.completeButton}
      loading={loading}
    >
      {t('inspection.completeBtn')}
    </Button>
  );
};
