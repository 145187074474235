import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { Validation, ValidationId } from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { focusStore } from 'stores/focus';
import { ContainerTemplatesMap } from 'stores/container';
import { EventFilter, FilterValue, GlobalFilters, UIEventsMap, VisibilityBindingsMap } from './uiEvent.types';
import { createUiEventFilters } from './uiEvent.utils';

const devtoolsOptions: DevtoolsOptions = {
  name: 'uiEvents',
  store: 'uiEvents',
  enabled: process.env.NODE_ENV === 'development',
};

interface UiEventState {
  uiEvents: UIEventsMap;
  validations: Record<ValidationId, Validation>;
  visibilityBindings: VisibilityBindingsMap;
  filters: {
    events: EventFilter[];
    global: GlobalFilters;
    values: FilterValue[];
  };
}

interface UiEventActions {
  setUIEvents: (uiEvents: UIEventsMap, containerTemplates?: ContainerTemplatesMap) => void;
  setFilterValues: (values: FilterValue[]) => void;
  setGlobalFilters: (filters: Partial<GlobalFilters>) => void;
  setValidations: (validation?: Record<ValidationId, Validation>) => void;
  setVisibilityBindings: (visibilityBindings?: VisibilityBindingsMap) => void;
  reset: () => void;
}

const uiEventInitialState: UiEventState = {
  uiEvents: {},
  validations: {},
  visibilityBindings: {},
  filters: {
    events: [],
    values: [],
    global: {
      outOfBounds: false,
    },
  },
};

export const uiEventStore = create(
  devtools(
    immer<UiEventState & UiEventActions>((set) => ({
      ...uiEventInitialState,
      setUIEvents: (uiEvents, containerTemplates) => {
        const filters = createUiEventFilters(uiEvents, containerTemplates);
        set((state) => {
          state.uiEvents = uiEvents;
          state.filters.events = filters;
        });
      },
      setFilterValues: (values) => {
        set((state) => {
          state.filters.values = values;
        });
        focusStore.getState().blurContainer();
      },
      setGlobalFilters: (filters = {}) => {
        set((state) => {
          Object.assign(state.filters.global, filters);
        });
        focusStore.getState().blurContainer();
      },
      setValidations: (validations?: Record<ValidationId, Validation>) => {
        set({ validations });
      },
      setVisibilityBindings: (visibilityBindings) => {
        set({ visibilityBindings });
      },
      reset: () => {
        set(uiEventInitialState);
      },
    })),
    devtoolsOptions,
  ),
);

export const useUiEventStore = createStoreHook({ store: uiEventStore, useShallow });
